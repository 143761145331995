<template>
  <v-autocomplete
    :disabled="disabled"
    :error-messages="errorMessages"
    :filter="fieldFilter"
    :items="serviceList"
    :value="value"
    @focus="$emit('update:error-messages', '')"
    @input="$emit('input', $event)"
    chips="chips"
    item-text="name"
    item-value="name"
    label="Serviço"
    no-data-text="Selecione um serviço"
    ref="service"
    return-object="return-object"
  >
    <template v-slot:selection="data">
      <v-chip
        :input-value="data.selected"
        @click:close="$emit('input', null)"
        @click="data.select"
        close="close"
        v-bind="data.attrs"
      >
        <v-avatar left="left" :class="data.item.color">
          <v-icon color="white" small="small">{{
            data.item.icon
          }}</v-icon> </v-avatar
        ><span>{{ data.item.name }}</span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-avatar class="mr-3" left="left" :class="data.item.color" size="30">
        <v-icon color="white" small="small">{{ data.item.icon }}</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle>Serviço</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "service-autocomplete",
  props: ["disabled", "errorMessages", "value"],

  data: () => ({
    serviceList: [
      {
        id: "-M-zupd_55kPHGHmjCn4",
        name: "Banho",
        icon: "mdi-shower-head",
        color: "blue lighten-1",
      },
      {
        id: "-M-zvD7TvDNeQ7fdgXot",
        name: "Tosa",
        icon: "mdi-content-cut",
        color: "deep-orange lighten-1",
      },
      {
        id: "-M-zvSvubkxsO75anmcB",
        name: "Embelezamento",
        icon: "mdi-auto-fix",
        color: "deep-purple darken-1",
      },
      {
        id: "-M-zvVjTO-vizS5Adsxl",
        name: "Cirurgia",
        icon: "mdi-medical-bag",
        color: "light-blue lighten-1",
      },
      {
        id: "-M-zvXY8Bs9SZ7IiHoPv",
        name: "Internação",
        icon: "mdi-hospital-building",
        color: "cyan darken-1",
      },
      {
        id: "-M-zvZL63yAglBi7qmYC",
        name: "Vacinas",
        icon: "mdi-pill",
        color: "green darken-1",
      },
      {
        id: "-M-zvbSWlls2Un-VhqWm",
        name: "Hospedagem",
        icon: "mdi-home-modern",
        color: "indigo darken-1",
      },
      {
        id: "-M-zvdYFtq8wjincpD22",
        name: "Outros",
        icon: "mdi-playlist-edit",
        color: "grey darken-1",
      },
    ],
  }),

  methods: {
    fieldFilter(item, queryText) {
      const text = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    focus() {
      this.$refs.service.focus();
    },
  },
};
</script>
