<template>
  <v-autocomplete
    :disabled="disabled"
    :filter="fieldFilter"
    :items="['Novo', 'Em andamento', 'Finalizado', 'Cancelado']"
    :value="value"
    @input="$emit('input', $event)"
    chips="chips"
    label="Status"
  >
    <template v-slot:selection="data">
      <v-chip
        :input-value="data.selected"
        @click="data.select"
        v-bind="data.attrs"
      >
        <v-avatar left="left" :class="statusColor(data.item)">
          <v-icon color="white">{{ statusIcon(data.item) }}</v-icon> </v-avatar
        ><span>{{ data.item }}</span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-avatar
        class="mr-3"
        left="left"
        :class="statusColor(data.item)"
        size="30"
      >
        <v-icon color="white">{{ statusIcon(data.item) }}</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "status-autocomplete",
  props: ["disabled", "value"],

  methods: {
    fieldFilter(item, queryText) {
      const text = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    statusIcon(v) {
      return `mdi-alpha-${v?.substr(0, 1).toLowerCase()}`;
    },

    statusColor(v) {
      switch (v.toLowerCase()) {
        case "novo":
          return "light-blue lighten-1";
        case "em andamento":
          return "deep-orange lighten-1";
        case "finalizado":
          return "accent";
        case "cancelado":
          return "grey";
        default:
          return "accent";
      }
    },
  },
};
</script>
