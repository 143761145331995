<template>
  <v-row justify="center">
    <v-dialog
      :max-width="widthComputed"
      :value="value"
      @input="$emit('input', $event)"
      scrollable="scrollable"
    >
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px" v-if="loading">
          <v-skeleton-loader
            class="mx-auto mt-5 mb-10"
            :boilerplate="boilerplate"
            :key="`skeleton-${item}`"
            max-height="100px"
            max-width="800px"
            ref="skeleton"
            type="image"
            v-for="item in 7"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-text class="pr-10" style="height: 800px" v-else>
          <v-timeline dense="dense" v-if="!!items.length">
            <v-slide-x-reverse-transition
              group="group"
              hide-on-leave="hide-on-leave"
            >
              <v-timeline-item
                :color="statusColor(item.title)"
                :icon="statusIcon(item.title)"
                :key="item.createdAt"
                :small="!item.title"
                fill-dot="fill-dot"
                v-for="item in items"
              >
                <v-alert
                  :value="true"
                  color="grey lighten-2"
                  outlined="outlined"
                  text="text"
                >
                  <v-row>
                    <v-col class="py-0 mb-3 ml-1" cols="12"
                      ><span :class="createdAtClass">{{
                        getCreatedAt(item.createdAt)
                      }}</span></v-col
                    >
                    <v-col class="py-0" cols="12">
                      <v-chip
                        class="mb-2"
                        outlined="outlined"
                        :color="statusColor(item.title)"
                        small="small"
                        ><span class="overline">{{ item.title }}</span></v-chip
                      >
                      <v-chip class="mb-2 ml-2" small="small">
                        <v-icon left="left" small="small">mdi-paw</v-icon
                        ><span class="overline">{{ item.animal }}</span>
                      </v-chip>
                      <v-chip
                        class="mb-2 ml-2"
                        outlined="outlined"
                        small="small"
                        v-if="item.status"
                        ><span class="overline"
                          >Status<span class="font-weight-bold">{{
                            " " + item.status
                          }}</span></span
                        ></v-chip
                      >
                    </v-col>
                    <v-col :class="textClass" cols="12" v-if="item.comments"
                      ><span class="font-weight-medium">Obs.</span
                      ><span class="font-weight-light">{{
                        ": " + item.comments
                      }}</span></v-col
                    >
                    <v-col :class="textClass" cols="12" v-if="item.details"
                      ><span class="font-weight-medium">Detalhes</span
                      ><span class="font-weight-light">{{
                        ": " + item.details
                      }}</span></v-col
                    >
                    <v-col :class="textClass" cols="12" v-if="item.anamnese"
                      ><span class="font-weight-medium">Anamnese</span
                      ><span class="font-weight-light">{{
                        ": " + item.anamnese
                      }}</span></v-col
                    >
                  </v-row>
                </v-alert>
              </v-timeline-item>
            </v-slide-x-reverse-transition>
            <div
              style="padding-top: 10px; height: 84px"
              v-if="items.length > 5"
              v-observe-visibility="{
                callback: visibilityChanged,
                throttle: 200,
                intersection: { rootMargin: '100px' },
              }"
            >
              <spinner v-if="spinner" color="#BDBDBD"></spinner>
            </div>
          </v-timeline>
          <v-container class="mx-auto" v-else>
            <div class="font-weight-light title">
              Nenhum histórico para este animal
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text="text" @click="$emit('input', false)"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Spinner from "@/components/Spinner";

import { db } from "../plugins/google/firebase";

export default {
  name: "logs-dialog",
  components: { Spinner },
  props: {
    animalId: String,
    title: String,
    value: Boolean,
  },

  data: () => ({
    boilerplate: true,
    loading: true,
    spinner: false,
    items: [],
    animalRef: null,
    limit: 6,
    totalSize: 0,

    createdAtClass: "body-2 grey--text text--darken-3 font-weight-light",
    textClass: "mt-1 py-0 grey--text text--darken-2",
  }),

  mounted() {
    if (this.animalId) {
      this.animalRef = db
        .collection(this.$store.getters.COLLECTION_ANIMALS)
        .doc(this.animalId);
    }
  },

  beforeDestroy() {
    this.logUnsubscribe && this.logUnsubscribe();
  },

  computed: {
    widthComputed() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "600px";
        case "sm":
          return "600px";
        case "md":
          return "800px";
        case "lg":
          return "1000px";
        case "xl":
          return "1200px";
        default:
          return "800px";
      }
    },
  },

  watch: {
    animalId(v) {
      this.animalRef = db
        .collection(this.$store.getters.COLLECTION_ANIMALS)
        .doc(v);
      this.loading = true;
      this.totalSize = 0;
      this.limit = 6;
      this.items = [];
    },

    value(v) {
      clearTimeout(this.timeoutId);
      this.boilerplate = true;
      if (v) {
        this.timeoutId = setTimeout(() => (this.boilerplate = false), 250);
        this.firstLoad();
      }
    },
  },

  methods: {
    firstLoad() {
      if (this.items.length || !this.animalRef) {
        return;
      }
      this.logUnsubscribe && this.logUnsubscribe();

      this.logUnsubscribe = this.animalRef
        .collection("logs")
        .onSnapshot((v) => {
          this.totalSize = v.size;
          this.loadData();
        });
    },

    getCreatedAt(v) {
      return this.$day(v).format("DD MMM YYYY [às] HH:mm");
    },

    async loadData() {
      if (!this.animalRef) {
        return;
      }

      try {
        const logsRef = this.animalRef.collection("logs");
        const items = [];
        const snap = await logsRef
          .orderBy("createdAt", "desc")
          .limit(this.limit)
          .get();
        snap.forEach((doc) => items.push(doc.data()));
        this.items = items;
      } catch (e) {
        this.$snackbar.show({
          title: "Algo deu errado ao carregar o histórico",
        });
      } finally {
        this.spinner = false;
        this.loading = false;
      }
    },

    statusIcon(v) {
      if (!v) {
        return "";
      }
      switch (v) {
        case "Banho":
          return "mdi-shower-head";
        case "Tosa":
          return "mdi-content-cut";
        case "Embelezamento":
          return "mdi-auto-fix";
        case "Cirurgia":
          return "mdi-medical-bag";
        case "Internação":
          return "mdi-hospital-building";
        case "Vacinas":
          return "mdi-pill";
        case "Hospedagem":
          return "mdi-home-modern";
        case "Outros":
          return "mdi-playlist-edit";
        default:
          return "mdi-clock";
      }
    },

    statusColor(v) {
      if (!v) {
        return "";
      }
      switch (v) {
        case "Banho":
          return "blue lighten-1";
        case "Tosa":
          return "deep-orange lighten-1";
        case "Embelezamento":
          return "deep-purple darken-1";
        case "Cirurgia":
          return "light-blue lighten-1";
        case "Internação":
          return "cyan darken-1";
        case "Vacinas":
          return "green darken-1";
        case "Hospedagem":
          return "indigo darken-1";
        case "Outros":
          return "grey darken-1";
        default:
          return "info";
      }
    },

    async visibilityChanged(isVisible) {
      if (isVisible && this.totalSize > this.items.length) {
        this.spinner = true;
        this.limit += 10;
        this.$nextTick(() => {
          this.loadData();
        });
      }
    },
  },
};
</script>
