<template>
  <v-expansion-panels
    :value="value"
    @input="$emit('input', $event)"
    focusable="focusable"
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        v-slot="{ open }"
        :class="{ 'px-3': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters="no-gutters">
          <v-col class="text--secondary" cols="12">
            <v-fade-transition leave-absolute="leave-absolute"
              ><span v-if="open">Qual o dia e horário?</span>
              <v-row
                v-else
                no-gutters="no-gutters"
                style="width: 100%"
              >
                <v-col cols="12">
                  <v-chip
                    class="mr-2"
                    color="rgba(101,101,191, 0.15)"
                    v-if="$vuetify.breakpoint.smAndUp"
                    >Inicia em</v-chip
                  >
                  <v-chip outlined="outlined">
                    <v-icon left="left" v-if="$vuetify.breakpoint.smAndUp"
                      >mdi-clock-outline</v-icon
                    ><span>{{ startedAtMasked }}</span>
                  </v-chip>
                  <v-icon class="mx-1" small="small">mdi-arrow-right</v-icon>
                  <v-chip
                    class="mr-2"
                    color="rgba(101,101,191, 0.15)"
                    v-if="$vuetify.breakpoint.smAndUp"
                    >Termina em</v-chip
                  >
                  <v-chip outlined="outlined">
                    <v-icon left="left" v-if="$vuetify.breakpoint.smAndUp"
                      >mdi-clock-check-outline</v-icon
                    ><span>{{ endedAtMasked }}</span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="8" md="4">
            <date-time-picker
              :disabled="disabled"
              :value="startedAt"
              @input="$emit('update:startedAt', $event)"
              label="Inicia em"
              prependIcon="mdi-calendar-today"
              type="date"
            ></date-time-picker>
          </v-col>
          <v-col cols="4" md="2">
            <date-time-picker
              :disabled="disabled"
              :value="startedAt"
              @input="$emit('update:startedAt', $event)"
              label="Horário"
              prependIcon="mdi-calendar-clock"
              type="time"
            ></date-time-picker>
          </v-col>
          <v-col cols="8" md="4">
            <date-time-picker
              :disabled="disabled"
              :value="endedAt"
              @input="$emit('update:endedAt', $event)"
              label="Termina em"
              prependIcon="mdi-calendar"
              type="date"
            ></date-time-picker>
          </v-col>
          <v-col cols="4" md="2">
            <date-time-picker
              :disabled="disabled"
              :value="endedAt"
              @input="$emit('update:endedAt', $event)"
              label="Horário"
              prependIcon="mdi-calendar-clock"
              type="time"
            ></date-time-picker>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DateTimePicker from "@/components/DateTimePicker.vue";

export default {
  name: "date-expansion-panel",
  components: { DateTimePicker },
  props: ["disabled", "endedAt", "startedAt", "value"],

  computed: {
    endedAtMasked() {
      return this.$utils.calendar(this.endedAt, "datetime");
    },

    startedAtMasked() {
      return this.$utils.calendar(this.startedAt, "datetime");
    },
  },
};
</script>
