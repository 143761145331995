import { db } from "../plugins/google/firebase";
import firestore from "../plugins/firestore";
import $store from "@/store";

const { COLLECTION_DOCUMENTS_ITEMS } = $store.getters,
  SOURCE = { source: "cache" };

export default {
  data: () => ({
    items: [],
    loadingItems: false,
  }),

  computed: {
    badgeItems() {
      return this.items.length > 0;
    },

    totalItems() {
      return this.items.reduce((accumulator, c) => {
        const currentValue = c.price * c.quantity;
        return accumulator + currentValue;
      }, 0);
    },
  },

  methods: {
    _addItem({ item, customerId, documentId, scheduleId }) {
      const ref = db.collection(COLLECTION_DOCUMENTS_ITEMS).doc();
      const doc = {
        createdAt: this.$day().toJSON(),
        customerId,
        id: ref.id,
        itemId: item.id,
        name: item.name,
        price: item.price,
        quantity: 1,
      };

      if (documentId) {
        doc.documentId = documentId;
      } else if (scheduleId) {
        doc.scheduleId = scheduleId;
      }

      this.items.push(doc);
      return doc;
    },

    _deleteItem(item) {
      const index = this.items.findIndex(v => v.id === item.id);
      this.items.splice(index, 1);
    },

    async _getCustomerItems({ customerId, documentId }) {
      if (!customerId) {
        return null;
      }

      const batch = this._getWriteBatch();

      try {
        const data = await db.collection(COLLECTION_DOCUMENTS_ITEMS)
          .where("customerId", "==", customerId)
          .get(SOURCE);

        const items = JSON.parse(JSON.stringify(this.items.filter(v => v.documentId && !v.scheduleId)));

        data.forEach(snap => {
          const doc = snap.data();
          if (!doc.scheduleReady) {
            return;
          }
          if (doc.documentId && documentId !== doc.documentId) {
            return;
          }
          items.push(this._parseDocItems(snap.id, doc));

          batch && batch.set(snap.ref, firestore.parseDocumentItems({
            documentId,
            item: doc,
            scheduleStatus: "Finalizado",
          }));
        });

        this.items = items;

        batch && this.$nextTick(() => {
          batch.commit();
          this.docRef && this.docRef.update({ total: this.totalItems });
        });
      } catch (e) {
        return null;
      }
    },

    async _getDocumentItems(documentId) {
      await this._getItemsFromApi("documentId", documentId);
    },

    async _getScheduleItems(scheduleId) {
      await this._getItemsFromApi("scheduleId", scheduleId);
    },

    async _getItemsFromApi(fieldPath, fieldId) {
      if (!fieldId) {
        return null;
      }

      try {
        const data = await db.collection(COLLECTION_DOCUMENTS_ITEMS)
          .where(fieldPath, "==", fieldId)
          .get(SOURCE);

        const items = [];

        data.forEach(snap => {
          const doc = snap.data();
          items.push(this._parseDocItems(snap.id, doc));
        });

        this.items = items;
      } catch (e) {
        return null;
      }
    },

    _getWriteBatch() {
      return this.inserting ? null : db.batch();
    },

    _getFirestoreItems({ documentId, scheduleStatus }) {
      if (!this.items.length) {
        return null;
      }

      const items = JSON.parse(JSON.stringify(this.items));
      return items.map(item => {
        const ref = db.collection(COLLECTION_DOCUMENTS_ITEMS).doc(item.id);
        const doc = firestore.parseDocumentItems({ item, documentId, scheduleStatus });
        return { ref, doc };
      });
    },

    _parseDocItems(id, doc) {
      return {
        customerId: doc.customerId,
        documentId: doc.documentId,
        id: id,
        itemId: doc.itemId,
        name: doc.name,
        price: doc.price,
        quantity: doc.quantity,
        scheduleId: doc.scheduleId,
        scheduleReady: !!doc.scheduleReady,
      };
    },

    _saveItemAction(item) {
      const index = this.items.findIndex(v => v.id === item.id);
      return Object.assign(this.items[index], item);
    },
  },
};
