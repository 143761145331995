<template>
  <v-container fluid="fluid">
    <v-dialog v-model="editedItemDialog" max-width="500px">
      <v-card>
        <v-card-title
          ><span class="headline">{{ formTitle }}</span></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Item"
                  v-model="editedItem.name"
                  readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  @keypress.enter="$refs.price.blur()"
                  autofocus="autofocus"
                  label="Valor (R$)"
                  placeholder="0,00"
                  ref="price"
                  type="tel"
                  v-currency="priceOptions"
                  v-model="editedItem.price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  @keypress.enter="$refs.quantity.blur()"
                  label="Quantidade"
                  placeholder="0,00"
                  ref="quantity"
                  type="tel"
                  v-currency="quantityOptions"
                  v-model="editedItem.quantity"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text="text" @click="closeItemAction"
            >Cancelar</v-btn
          >
          <v-btn color="accent" text="text" @click="saveItemAction"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      class="elevation-1"
      :footer-props="itemsFooterProps"
      :headers="itemsHeaders"
      :hide-default-footer="!items.length"
      :hide-default-header="!items.length"
      :items-per-page="5"
      :items="items"
      :loading="loading"
      disable-filtering="disable-filtering"
      disable-sort="disable-sort"
    >
      <template v-slot:[`item.price`]="{ item }"
        ><span>{{ $utils.decimal(item.price, 2) }}</span></template
      >
      <template v-slot:[`item.quantity`]="{ item }"
        ><span>{{ $utils.decimal(item.quantity, 3) }}</span></template
      >
      <template v-slot:[`item.total`]="{ item }"
        ><span>{{ calcTotal(item) }}</span></template
      >
      <template v-slot:[`item.document`]="{ item }">
        <v-btn
          :title="headerDocumentTip"
          @click="$emit('item-navigate', item)"
          icon="icon"
          text="text"
          v-if="showDocumentColumn(item)"
        >
          <v-icon :size="22" color="green"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </v-btn>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          class="mr-1"
          :disabled="actionDisabled(item)"
          @click="editItemAction(item)"
          icon="icon"
          text="text"
          title="Editar"
        >
          <v-icon :size="22">mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          :disabled="actionDisabled(item)"
          @click="deleteItemAction(item)"
          icon="icon"
          text="text"
          title="Excluir"
        >
          <v-icon :size="22">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`header.document`]="{}"
        ><span>{{ headerDocumentLabel }}</span></template
      >
    </v-data-table>
    <collection-dialog
      @data-selected="onAddItem"
      title="Selecione um item"
      type="items"
      v-if="collectionDialog"
      v-model="searchItemsDialog"
    ></collection-dialog>
  </v-container>
</template>

<script>
import CollectionDialog from "@/components/CollectionDialog.vue";
import { parseCurrency } from "vue-currency-input";

export default {
  name: "document-items-data-table",
  props: {
    disableEdit: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    scheduleLink: { type: Boolean, default: false },
    type: String,
  },
  components: { CollectionDialog },

  data: () => ({
    itemsFooterProps: {
      "items-per-page-options": [5, 10],
    },
    collectionDialog: false,
    searchItemsDialog: false,
    editedItemDialog: false,
    editedIndex: -1,
    editedItem: {
      itemId: "",
      name: "",
      price: "",
      quantity: "",
    },
    defaultItem: {
      itemId: "",
      name: "",
      price: "",
      quantity: "",
    },
    currencyOptions: {
      allowNegative: false,
      autoDecimalMode: true,
      currency: null,
      precision: 2,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Adicionar item" : "Editar item";
    },

    itemsHeaders() {
      const headers = [
        { text: "Descrição", align: "start", value: "name" },
        { text: "Valor (R$)", align: "end", value: "price" },
        { text: "Quantidade", align: "end", value: "quantity" },
        { text: "Total (R$)", align: "end", value: "total" },
        { text: "Doc", align: "center", value: "document" },
        { text: "Ações", align: "end", value: "action" },
      ];
      this.disableEdit && headers.pop();
      return headers;
    },

    headerDocumentLabel() {
      return this.type === "schedule" ? "Venda" : "Agendamento";
    },

    headerDocumentTip() {
      return this.type === "schedule" ? "Ir para venda" : "Ir para agendamento";
    },

    priceOptions() {
      return this.currencyOptions;
    },

    quantityOptions() {
      const options = Object.assign({}, this.currencyOptions);
      options.precision = 3;
      return options;
    },
  },

  methods: {
    actionDisabled({ documentId }) {
      return this.type === "schedule" && !!documentId;
    },

    closeItemAction() {
      this.editedItemDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    calcTotal(v) {
      return this.$utils.decimal(Number(v.price) * Number(v.quantity), 2);
    },

    deleteItemAction(item) {
      this.$dialog.show(
        "Remover item",
        "Tem certeza que quer remover este item?",
        [
          { text: "cancelar" },
          {
            text: "remover",
            style: "destrutive",
            onClick: () => this.$emit("item-delete", item),
          },
        ],
      );
    },

    editItemAction({ id }) {
      this.editedIndex = this.items.findIndex((v) => v.id === id);

      const editedItem = Object.assign({}, this.items[this.editedIndex]);
      editedItem.price = this.$utils.decimal(editedItem.price, 2);
      editedItem.quantity = this.$utils.decimal(editedItem.quantity, 3);

      this.editedItem = editedItem;
      this.editedItemDialog = true;
    },

    onAddItem(data) {
      !!data[0] && this.$emit("item-add", data[0]);
    },

    saveItemAction() {
      if (this.editedIndex > -1) {
        const event = Object.assign({}, this.editedItem);
        event.price = parseCurrency(event.price, this.priceOptions);
        event.quantity = parseCurrency(event.quantity, this.quantityOptions);
        this.$emit("item-save", event);
      }
      this.closeItemAction();
    },

    searchItems() {
      if (!this.collectionDialog) {
        this.collectionDialog = true;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.searchItemsDialog = true;
        }, 250);
      });
    },

    showDocumentColumn(v) {
      if (this.type === "schedule") {
        return !!v.documentId;
      } else {
        return !!v.scheduleId;
      }
    },
  },
};
</script>
