<template>
  <base-container class="pb-5">
    <template v-slot:header>
      <v-btn
        class="mx-2"
        @click="$router.go(-1)"
        color="accent"
        :disabled="loading"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        @click="validate"
        :disabled="!valid || loading || !editable"
        color="accent"
        >Salvar</v-btn
      >
    </template>
    <v-card
      class="mb-4 elevation-1"
      :class="cardMargins"
      :disabled="loading"
      :loading="loading"
    >
      <v-tabs v-model="tab" background-color="white" color="accent" height="52">
        <v-tab>
          <v-badge :value="badgeError" color="red" dot="dot"
            >Agendamento</v-badge
          >
        </v-tab>
        <v-tab>Detalhes</v-tab>
        <v-tab>
          <v-badge :value="badgeItems" color="accent" dot="dot">Itens</v-badge>
        </v-tab>
        <v-spacer></v-spacer>
        <v-tooltip color="#212121" bottom="bottom" v-if="!!doc.animal.id">
          <template v-slot:activator="{ on }">
            <v-btn
              class="mt-2 mx-3"
              @click="showLogs"
              icon="icon"
              key="history"
              large="large"
              text="text"
              v-on="on"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn> </template
          ><span>Histórico do animal</span>
        </v-tooltip>
        <transition appear="appear" name="bounce" mode="out-in">
          <v-btn
            class="mt-2 mr-4 elevation-2"
            @click="editable = !editable"
            color="deep-orange lighten-1"
            dark="dark"
            fab="fab"
            key="edit"
            small="small"
            v-if="!inserting && tab < 2"
          >
            <v-icon>{{ editable ? "mdi-close" : "mdi-pencil" }}</v-icon>
          </v-btn>
          <v-btn
            class="mt-2 mr-4 elevation-2"
            @click="addItems"
            color="accent"
            dark="dark"
            fab="fab"
            key="add"
            small="small"
            v-else-if="tab === 2"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </transition>
        <v-tab-item>
          <v-card-text class="pb-0">
            <date-expansion-panel
              :disabled="!editable"
              :endedAt.sync="doc.endedAt"
              :startedAt.sync="doc.startedAt"
              v-model="expansion"
            ></date-expansion-panel>
            <v-row class="mt-3">
              <v-col cols="12" md="4">
                <service-autocomplete
                  :disabled="!editable"
                  :error-messages.sync="error.service"
                  ref="service"
                  v-model="doc.service"
                ></service-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <accounts-autocomplete
                  :disabled="!editable"
                  :items="accountsList"
                  :error-messages.sync="error.accounts"
                  v-model="doc.accounts"
                ></accounts-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <status-autocomplete
                  :disabled="!editable"
                  v-model="doc.status"
                ></status-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!editable"
                  :error-messages="error.animal"
                  :value="doc.animal.name"
                  @click="searchAnimal"
                  @click:prepend-inner="searchAnimal"
                  @focus="error.animal = ''"
                  filled="filled"
                  label="Animal"
                  prepend-inner-icon="mdi-magnify"
                  readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!editable"
                  :value="doc.customer.name"
                  filled="filled"
                  label="Cliente"
                  readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :disabled="!editable"
                  auto-grow="auto-grow"
                  filled="filled"
                  label="Observações"
                  row-height="20"
                  rows="2"
                  v-model="doc.comments"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="py-0">
            <v-row>
              <v-col class="pb-0" cols="12">
                <v-textarea
                  :disabled="!editable"
                  auto-grow="auto-grow"
                  filled="filled"
                  label="Detalhes"
                  row-height="20"
                  rows="2"
                  v-model="doc.details"
                ></v-textarea>
              </v-col>
              <v-col class="py-0" cols="12">
                <v-textarea
                  :disabled="!editable"
                  auto-grow="auto-grow"
                  filled="filled"
                  label="Anamnese"
                  row-height="20"
                  rows="2"
                  v-model="doc.anamnese"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <document-items-data-table
            :items="items"
            :loading="loadingItems"
            @item-add="handleItemAdd"
            @item-delete="handleItemDelete"
            @item-navigate="navigateTo"
            @item-save="handleItemSave"
            ref="items"
            type="schedule"
          ></document-items-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <collection-dialog
      @data-selected="onSelectAccount"
      title="Selecione o animal"
      type="animals"
      v-if="collectionDialog"
      v-model="dialogAccounts"
    ></collection-dialog>
    <logs-dialog
      :animalId="doc.animal.id"
      title="Histórico do animal"
      v-model="dialogLogs"
    ></logs-dialog>
  </base-container>
</template>

<script>
import AccountsAutocomplete from "./components/AccountsAutocomplete.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import CollectionDialog from "@/components/CollectionDialog.vue";
import DateExpansionPanel from "./components/DateExpansionPanel.vue";
import DocumentItemsDataTable from "@/components/DocumentItemsDataTable.vue";
import LogsDialog from "@/components/LogsDialog.vue";
import ServiceAutocomplete from "./components/ServiceAutocomplete.vue";
import StatusAutocomplete from "./components/StatusAutocomplete.vue";

import { db } from "@/plugins/google/firebase";
import { mask } from "vue-the-mask";
import $store from "@/store";
import firestore from "../../plugins/firestore";
import documentItemsMixin from "@/mixin/documentItemsMixin";

const { COLLECTION_ACCOUNTS, COLLECTION_SCHEDULES } = $store.getters,
  SOURCE = { source: "cache" };

export default {
  name: "schedule-edit",
  directives: { mask },
  mixins: [documentItemsMixin],
  components: {
    AccountsAutocomplete,
    BaseContainer,
    CollectionDialog,
    DateExpansionPanel,
    DocumentItemsDataTable,
    LogsDialog,
    ServiceAutocomplete,
    StatusAutocomplete,
  },
  props: ["type"],

  data: () => ({
    editable: false,
    loading: false,
    valid: true,
    tab: 0,
    inserting: true,
    collectionDialog: false,

    doc: {
      accounts: [],
      anamnese: "",
      animal: {
        name: "",
      },
      comments: "",
      customer: {
        name: "",
      },
      details: "",
      endedAt: "",
      service: null,
      startedAt: "",
      status: "Novo",
    },
    initialStatus: "",

    accountsList: [],
    error: {
      accounts: "",
      animal: "",
      service: "",
    },

    dialogAccounts: false,
    dialogLogs: false,
    expansion: false,
    expansionBottom: false,
  }),

  created() {
    this.inserting = this.type === "add";
  },

  mounted() {
    this.onStart();
  },

  watch: {
    "doc.startedAt"(v) {
      if (this.$day(v).isAfter(this.doc.endedAt)) {
        this.doc.endedAt = this.$day(v).add(30, "minute").toJSON();
      }
    },

    "doc.endedAt"(v) {
      if (this.$day(v).isBefore(this.doc.startedAt)) {
        this.doc.startedAt = this.$day(v).toJSON();
      }
    },

    expansionBottom(v) {
      if (v == 0) {
        setTimeout(() => {
          this.$vuetify.goTo(600, {
            duration: 1000,
            offset: 0,
            easing: "easeInOutCubic",
          });
        }, 400);
      }
    },
  },

  computed: {
    cardMargins() {
      return this.$vuetify.breakpoint.xlOnly ? "px-3 py-1" : undefined;
    },

    badgeError() {
      return !!Object.values(this.error).filter((v) => !!v).length;
    },
  },

  methods: {
    async onStart() {
      if (this.$route.params.docRef) {
        this.getDataFromApi(this.$route.params.docRef);
      } else if (this.type === "edit") {
        this.$router.replace({ name: "schedules" });
      } else {
        this.docRef = db.collection(COLLECTION_SCHEDULES).doc();
        this.editable = true;
        setTimeout(() => {
          this.$refs.service.focus();
        }, 250);

        const day = this.$day();
        this.doc.startedAt = day.startOf("minute").toJSON();
        this.doc.endedAt = day.startOf("minute").add(30, "minute").toJSON();
      }

      const snap = await db
        .collection(COLLECTION_ACCOUNTS)
        .where("role", "==", "Outros")
        .get(SOURCE);
      this.accountsList = snap.docs.map((v) => ({
        id: v.id,
        name: v.get("name"),
      }));
    },

    addItems() {
      if (!this.doc.animal.id) {
        this.error.animal = "Por favor escolha o animal";
        this.tab = 0;
        return;
      }
      this.$refs.items && this.$refs.items.searchItems();
    },

    async getDataFromApi(docRef) {
      try {
        this.docRef = db.collection(COLLECTION_SCHEDULES).doc(docRef);

        const snap = await this.docRef.get(SOURCE);

        const doc = snap.data();
        const accountIds = doc.accounts;
        doc.accounts = [];
        this.initialStatus = doc.status;
        this.doc = doc;

        const ref = db.collection(COLLECTION_ACCOUNTS);
        const snaps = await Promise.all(
          accountIds.map((id) => ref.doc(id).get(SOURCE)),
        );
        this.doc.accounts = snaps.map((v) => ({
          id: v.id,
          name: v.get("name"),
        }));

        setTimeout(() => this._getScheduleItems(this.docRef.id), 250);
      } catch (e) {
        this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
      }
    },

    handleItemAdd(v) {
      const item = this._addItem({
        item: v,
        customerId: this.doc.customer.id,
        scheduleId: this.docRef.id,
      });
      if (!this.inserting) {
        firestore.setDocumentItem(item).catch(() => {
          this.$messages.error({ text: "Houve um problema ao salvar o item" });
        });
      }
    },

    handleItemDelete(v) {
      this.loadingItems = true;
      firestore
        .deleteScheduleItem(v)
        .then(() => this._deleteItem(v))
        .catch(() => {
          this.$messages.error({
            text: "Algo deu errado ao remover o item. Parece que estamos sem internet",
          });
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },

    handleItemSave(v) {
      const item = this._saveItemAction(v);
      if (!this.inserting) {
        firestore.setDocumentItem(item).catch(() => {
          this.$messages.error({ text: "Houve um problema ao salvar o item" });
        });
      }
    },

    async onSelectAccount(data) {
      if (!data[0]) {
        return;
      }

      const doc = data[0];
      this.doc.animal = doc;

      if (doc.owner) {
        const snap = await db
          .collection(COLLECTION_ACCOUNTS)
          .doc(doc.owner.id)
          .get(SOURCE);
        snap.exists &&
          (this.doc.customer = { id: snap.id, name: snap.get("name") });
      }
    },

    getStateData() {
      const doc = JSON.parse(JSON.stringify(this.doc));
      doc.accounts = [];
      this.doc.accounts.forEach((v) => {
        doc.accounts.push(v.id);
      });
      doc.animal = { id: doc.animal.id, name: doc.animal.name };
      doc.service = { id: doc.service.id, name: doc.service.name };
      return doc;
    },

    async saveToFirestore() {
      try {
        this.loading = true;

        const doc = this.getStateData();
        doc.updatedAt = new Date().toJSON();

        let items = null;
        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
          items = this._getFirestoreItems({ scheduleStatus: doc.status });
        }

        await firestore.setSchedule(this.docRef, doc, items);

        this.editable = false;
        this.inserting = false;

        this.$snackbar.show({ title: "Dados salvos com sucesso" });
      } catch (e) {
        this.$messages.error({
          text: e.items || "Erro ao salvar dados, sem conexão",
        });
      } finally {
        this.loading = false;
      }
    },

    validate() {
      if (!this.doc.accounts.length) {
        this.error.accounts = "Selecione ao menos um atendente";
      }
      if (!this.doc.animal.id) {
        this.error.animal = "Por favor escolha o animal";
      }
      if (!this.doc.service) {
        this.error.service = "Selecione o serviço";
      }

      if (Object.values(this.error).filter((v) => !!v).length) {
        this.tab = 0;
        return;
      }

      this.saveToFirestore();
    },

    showLogs() {
      this.dialogLogs = true;
    },

    navigateTo(v) {
      if (this.type === "add" || !v.documentId) {
        return;
      }

      const onClick = () =>
        setTimeout(() => {
          this.$router.push({
            name: "document-edit",
            params: { type: "edit", docRef: v.documentId },
          });
        }, 100);

      this.$dialog.show(
        "Visualizar venda",
        "Deseja continuar e visualizar a venda correspondente ao item?",
        [
          { text: "cancelar" },
          { text: "Visualizar", style: "destrutive", onClick },
        ],
      );
    },

    searchAnimal() {
      if (!this.collectionDialog) {
        this.collectionDialog = true;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.dialogAccounts = true;
        }, 250);
      });
    },
  },
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
