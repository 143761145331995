<template>
  <div class="s-spinner">
    <div class="s-spinner-bounce1" :style="{ backgroundColor: color }"></div>
    <div class="s-spinner-bounce2" :style="{ backgroundColor: color }"></div>
    <div class="s-spinner-bounce3" :style="{ backgroundColor: color }"></div>
  </div>
</template>

<script>
export default {
  name: "spinner",

  props: {
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>

<style>
.s-spinner {
  margin: auto;
  text-align: center;
}

.s-spinner > div {
  width: 18px;
  height: 18px;
  display: inline-block;
  animation: s-spinner-bouncedelay 1s infinite ease-in-out both;
  border-radius: 100%;
  background-color: #fff;
}

.s-spinner .s-spinner-bounce1 {
  animation-delay: -0.32s;
}

.s-spinner .s-spinner-bounce2 {
  animation-delay: -0.16s;
}

@keyframes s-spinner-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
