<template>
  <v-autocomplete
    :disabled="disabled"
    :error-messages="errorMessages"
    :filter="fieldFilter"
    :items="items"
    :value="value"
    @focus="$emit('update:error-messages', '')"
    @input="$emit('input', $event)"
    chips="chips"
    item-text="name"
    item-value="name"
    label="Atendentes"
    multiple="multiple"
    no-data-text="Selecione um atendente"
    ref="accounts"
    return-object="return-object"
  >
    <template v-slot:selection="data">
      <v-chip
        :input-value="data.selected"
        @click:close="removeAccount(data.item)"
        @click="data.select"
        close="close"
        v-bind="data.attrs"
      >
        <v-avatar class="accent" left="left">
          <v-icon color="white" small="small"
            >mdi-account-edit</v-icon
          > </v-avatar
        ><span>{{ data.item.name }}</span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-avatar class="mr-3 accent" left="left" size="30">
        <v-icon color="white" small="small">mdi-account-edit</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle>Atendente</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "accounts-autocomplete",
  props: ["disabled", "items", "errorMessages", "value"],

  methods: {
    fieldFilter(item, queryText) {
      const text = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    focus() {
      this.$refs.accounts.focus();
    },

    removeAccount(item) {
      const accounts = JSON.parse(JSON.stringify(this.value));
      const index = accounts.indexOf(item);
      index > -1 && accounts.splice(index, 1);
      this.$emit("input", accounts);
    },
  },
};
</script>
